<template>
  <div class="proposal">
    <div class="box">
      <p>方案类型</p>
      <div class="zjbox">
        <l-select :option="options" v-model="datas"></l-select>
      </div>
    </div>
    <div class="box">
      <p>方案类型</p>
      <div class="zjbox">
        <l-select :option="options" v-model="datas"></l-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "proposal",
  data() {
    return {
      datas: '',
      options: [
        {name: '无明显受限',value:0, isshow: false},
        {name: '眼球震颤',value:1, isshow: false},
        {name: '外屏障碍',value:2, isshow: false},
        {name: '内收障碍',value:3, isshow: false},
        {name: '上转障碍',value:4, isshow: false},
        {name: '下转障碍',value:5, isshow: false},
        {name: '手动填写',value:6, isshow: false}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .proposal {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-right: 10vw;
    p {padding-right: 30px}
    .zjbox {
      width: 10vw;
    }
   }
</style>